<template>

  <div style="background-color: #F9F9F9;margin-top: 1px">

    <div class="product-details_box">
      <div class="product-details_container">
        <div class="product-wrap">
          <div style="margin-bottom: 40px">
            <!--            <nav style="color: #282828;font-size: 14px">-->
            <!--              <a href="/">产品</a> > <a>{{ name }}</a>-->
            <!--            </nav>-->
            <el-breadcrumb separator=">">
              <el-breadcrumb-item :to="{path:'/'}">首页</el-breadcrumb-item>
              <el-breadcrumb-item>{{ name }}</el-breadcrumb-item>
            </el-breadcrumb>
          </div>

          <div style="margin-bottom: 20px">
            <h2 class="product-title">
              <span style="color: #000000">关于</span>{{ name }}
            </h2>
          </div>

          <div class="product-brief">
            <p>
              MOS，是MOSFET的缩写。MOSFET金属-氧化物半导体场效应晶体管，简称金氧半场效晶体管。<br>
              MOS管是FET的一种（另一种为JFET结型场效应管），主要有两种结构形式：N沟道型和P沟道型；又根据场效应原理的不同，分为耗尽型（当栅压为零时有较大漏极电流）和增强型（当栅压为零，漏极电流也为零，必须再加一定的栅压之后才有漏极电流）两种。因此，MOS管可以被制构成P沟道增强型、P沟道耗尽型、N沟道增强型、N沟道耗尽型4种类型产品。
            </p>
          </div>
        </div>

      </div>
    </div>

    <div class="product-category_box">
      <div class="product-category_warp">

        <div class="product-category_item" v-for="(item,index) in productCategoryList" :key="index">
          <div class="product-category_content">
            <div style="margin-bottom: 10px;font-size: 20px">
              <a href="/" style="color: #000">{{ item.name }}</a>
            </div>
            <div class="font-size_14"
                 style="color: #54595F;text-align: justify;min-height: 160px;white-space: pre-wrap;line-height:1.6">
              {{ item.brief }}
            </div>
            <div class="learn-more">
              <a class="learn-more_button" :href="item.pdf" target="_blank">了解详情</a>
            </div>
          </div>
        </div>

      </div>
    </div>

    <!--    技术文档 begin-->
    <div class="technical-documents_box">
      <div class="technical-documents_warp">

        <div class="title-icon">
          <img src="/icon/pdf.png" width="20" height="20">
          <div>技术文档</div>
        </div>

        <div class="technical-documents_tab">
          <div style="text-align: left">
            <div class="flex" style="margin-bottom: 20px">
              <el-input v-model="search" placeholder="请输入产品型号"
                        style="width: 250px;margin-right: 40px" :disabled="true"></el-input>
              <el-radio-group v-model="radio" :disabled="true">
                <el-radio label="应用指南">应用指南</el-radio>
                <el-radio label="APPNOTE">APPNOTE</el-radio>
                <el-radio label="EVMUG">EVMUG</el-radio>
              </el-radio-group>
            </div>
            <el-table :data="tableData">
              <el-table-column label="产品型号" prop="goodsName" align="center"></el-table-column>
              <el-table-column label="更新日期" prop="address" align="center"></el-table-column>
              <el-table-column label="点击下载" align="center">
                <template #default="scope">
                  <!--                  -->
                  <img src="/icon/download.png" width="20" height="20" style="cursor: pointer"
                       @click="handleDownload(scope.row)" :title='`点击下载${scope.row.goodsName}.PDF`'>

                </template>
              </el-table-column>

            </el-table>
          </div>
        </div>
      </div>
    </div>
    <!--    技术文档 end-->


  </div>

</template>

<script>
import FileSave from 'file-saver'
export default {
  data() {
    return {
      name: '',
      search:'',
      radio:'应用指南',
      productCategoryList:[
        {
          name: 'GCP2N6849',
          brief: `•采用密封金属 TO-39 封装的 MOSFET 晶体管\n• 单脉冲雪崩能量额定值\n• 专为开关、电源、  电机控制和  放大器应用而设计`,
          pdf: '/pdf/GCP2N6849.pdf'
        },
        {
          name: 'GCFF9120',
          brief: `•开关速度快、损耗小，输入阻抗高，驱动功耗小安全工作区宽，温度稳定性好；\n• 替代国外型号：IRFE9120、IRFF9120；`,
          pdf: '/pdf/GCFF9120.pdf'
        },

      ],
      tableData:[
        {
          goodsName: 'GCP2N6849',
          address: '2023-2-10',
          pdf: '/pdf/GCP2N6849.pdf'
        },
        {
          goodsName: 'GCFF9120',
          address: '2023-2-10',
          pdf: '/pdf/GCFF9120.pdf'
        },
      ]
    }
  },
  created() {
    this.name = this.$route.params.name
  },
  methods:{
    handleDownload(res){
      console.log('res',res)
      FileSave.saveAs(res.pdf,`${res.goodsName}.pdf`)
    }
  }
}


</script>

<style lang="less" scoped>
.product-details_box {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 50px 10px;

  .product-details_container {
    min-height: 200px;
    max-width: 1400px;
    margin: 0 auto;

    .product-wrap {
      padding: 10px;

      .product-title {
        color: #0075FF;
        font-size: 20px;
        font-weight: normal;
        text-align: left;
      }

      .product-brief {
        color: #54595F;
        font-size: 14px;
        line-height: 1.6;
        text-align: justify;
      }
    }
  }
}

.product-category_box {
  padding: 0px 10px 50px 10px;

  .product-category_warp {
    min-height: 330px;
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;


    .product-category_item {
      width: 20%;
      margin-bottom: 20px;


      .product-category_content {
        height: 100%;
        background-color: #ffffff;
        padding: 15px;
        margin: 10px;
        border-top: 4px solid #0075FF;
        box-shadow: 0px 0px 1px 1px rgb(0 0 0 / 22%);
        transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .learn-more {
          opacity: 0;
          transition: opacity 1s ease;
          text-align: left;
          .learn-more_button {
            font-size: 12px;
            color: #ffffff;
            padding: 10px;
            background-color: #0075FF;
          }
        }

        &:hover .learn-more {
          opacity: 1;
        }
      }


    }
  }
}


.technical-documents_box {
  padding: 50px 0px 100px 0px;

  .technical-documents_warp {
    padding: 10px;
    max-width: 1400px;
    margin: 0 auto;

    .title-icon {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      font-size: 20px;
      gap: 10px;
    }

    .technical-documents_tab {
      background-color: #F9F9F9;
      transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
      padding: 15px;
    }
  }
}
</style>